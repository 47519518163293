<template>
  <div class="page-container">
    <div class="banner">
      Our Team
    </div>
    <b-container>
      <div class="title-section">
        <div class="title">
          China Team
        </div>
        <div class="divider">
          <img
            src="@/assets/images/divider.png"
            alt=""
          >
        </div>
      </div>
      <b-row>
        <b-col
          v-for="item in team.china"
          :key="item.id"
          class="person-card"
          cols="12"
          sm="6"
          md="6"
          lg="3"
          xl="3"
        >
          <div
            class="content"
            @click="showDetail(item)"
          >
            <div class="top">
              <img
                class="avatar"
                :src="item.avatar.url"
                alt=""
              >
            </div>
            <div class="info">
              <div class="name">
                {{ item.name }}
              </div>
              <div class="position">
                {{ item.position }}
              </div>
              <div class="contact">
                <a
                  v-if="item.linkedin"
                  :href="item.linkedin"
                  target="_blank"
                  class="icon"
                  @click.stop
                >
                  <img
                    src="@/assets/images/linkedin.svg"
                    alt=""
                  >
                </a>
                <a
                  v-if="item.mail"
                  :href="`mailto:${item.mail}`"
                  target="_blank"
                  class="icon"
                  @click.stop
                >
                  <img
                    src="@/assets/images/email.svg"
                    alt=""
                  >
                </a>
              </div>
              <div class="link">
                More about {{ getName(item.name) }}
                <img
                  src="@/assets/images/more.svg"
                  alt=""
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="title-section">
        <div class="title">
          Global Partner Team
        </div>
        <div class="divider">
          <img
            src="@/assets/images/divider.png"
            alt=""
          >
        </div>
      </div>
      <b-row>
        <b-col
          v-for="item in team.us"
          :key="item.id"
          class="person-card"
          cols="12"
          sm="6"
          md="6"
          lg="3"
          xl="3"
        >
          <div
            class="content"
            @click="showDetail(item)"
          >
            <div class="top">
              <img
                class="avatar"
                :src="item.avatar.url"
                alt=""
              >
            </div>
            <div class="info">
              <div class="name">
                {{ item.name }}
              </div>
              <div class="position">
                {{ item.position }}
              </div>
              <div class="contact">
                <a
                  v-if="item.linkedin"
                  :href="item.linkedin"
                  target="_blank"
                  class="icon"
                  @click.stop
                >
                  <img
                    src="@/assets/images/linkedin.svg"
                    alt=""
                  >
                </a>
                <a
                  v-if="item.mail"
                  :href="`mailto:${item.mail}`"
                  target="_blank"
                  class="icon"
                  @click.stop
                >
                  <img
                    src="@/assets/images/email.svg"
                    alt=""
                  >
                </a>
              </div>
              <div class="link">
                More about {{ getName(item.name) }}
                <img
                  src="@/assets/images/more.svg"
                  alt=""
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!--    <div-->
    <!--      v-for="item in team.management"-->
    <!--      :key="item.id"-->
    <!--      class="leader container"-->
    <!--    >-->
    <!--      <div class="avatar-container">-->
    <!--        <img-->
    <!--          class="avatar"-->
    <!--          :src="item.avatar.url | getSource"-->
    <!--          alt=""-->
    <!--        >-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <div class="name">-->
    <!--          {{ item.name }}-->
    <!--        </div>-->
    <!--        <div class="position">-->
    <!--          {{ item.position }}-->
    <!--        </div>-->
    <!--        <div class="desc">-->
    <!--          {{ item.introduction }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      v-if="!!team.member.length"-->
    <!--      class="members container"-->
    <!--    >-->
    <!--      <div-->
    <!--        v-for="item in team.member"-->
    <!--        :key="item.id"-->
    <!--        class="member"-->
    <!--      >-->
    <!--        <div class="all">-->
    <!--          <div>-->
    <!--            <img-->
    <!--              class="avatar"-->
    <!--              :src="item.avatar.url | getSource"-->
    <!--              alt=""-->
    <!--            >-->
    <!--            <div class="member_intro text-center">-->
    <!--              <div class="name">-->
    <!--                {{ item.name }}-->
    <!--              </div>-->
    <!--              <div class="position">-->
    <!--                {{ item.position }}-->
    <!--              </div>-->
    <!--              <div class="desc">-->
    <!--                {{ item.introduction }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      v-for="item in team.partner"-->
    <!--      :key="item.id"-->
    <!--      class="leader container"-->
    <!--    >-->
    <!--      <div class="avatar-container">-->
    <!--        <img-->
    <!--          class="avatar"-->
    <!--          :src="item.avatar.url | getSource"-->
    <!--          alt=""-->
    <!--        >-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <div class="name">-->
    <!--          {{ item.name }}-->
    <!--        </div>-->
    <!--        <div class="position">-->
    <!--          {{ item.position }}-->
    <!--        </div>-->
    <!--        <div class="desc">-->
    <!--          {{ item.introduction }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="title-section">-->
    <!--      <div class="title">-->
    <!--        Advisory Board / Venture Partners-->
    <!--      </div>-->
    <!--      <div class="divider">-->
    <!--        <img-->
    <!--          src="@/assets/images/divider.png"-->
    <!--          alt=""-->
    <!--        >-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      class="members container"-->
    <!--    >-->
    <!--      <div-->
    <!--        v-for="item in team.advisory"-->
    <!--        :key="item.id"-->
    <!--        class="member adviser"-->
    <!--      >-->
    <!--        <div class="all">-->
    <!--          <div>-->
    <!--            <img-->
    <!--              class="avatar"-->
    <!--              :src="item.avatar.url | getSource"-->
    <!--              alt=""-->
    <!--            >-->
    <!--            <div class="member_intro text-center">-->
    <!--              <div class="name">-->
    <!--                {{ item.name }}-->
    <!--              </div>-->
    <!--              <div class="position">-->
    <!--                {{ item.position }}-->
    <!--              </div>-->
    <!--              <div class="desc">-->
    <!--                {{ item.introduction }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <b-modal
      id="modal-lg"
      ref="personal"
      centered
      size="xl"
      :hide-header="true"
      :hide-footer="true"
    >
      <div
        v-if="current"
        class="current"
      >
        <div class="left">
          <img
            class="avatar"
            :src="current.avatar.url"
            alt=""
          >
        </div>
        <div class="right">
          <div class="name">
            {{ current.name }}
          </div>
          <div class="position">
            {{ current.position }}
          </div>
          <div class="desc">
            {{ current.introduction }}
          </div>
          <div class="contact">
            <a
              v-if="current.linkedin"
              :href="current.linkedin"
              target="_blank"
              class="icon"
              @click.stop
            >
              <img
                src="@/assets/images/linkedin.svg"
                alt=""
              >
            </a>
            <a
              v-if="current.mail"
              :href="`mailto:${current.mail}`"
              target="_blank"
              class="icon"
              @click.stop
            >
              <img
                src="@/assets/images/email.svg"
                alt=""
              >
            </a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { getTeam } from '@/api/team'

export default
@Component({})
class Team extends Vue {
  team = {
    china: [],
    us: [],
    asia: [],
    europe: [],
    medtech: [],
    consulting: []
  };

  current = null;

  mounted () {
    this.getTeam()
  }

  getName (name) {
    return name.split(',')[0]
  }

  @Watch('$i18n.locale')
  async getTeam () {
    const result = await getTeam()
    this.team = _.groupBy(result, 'group')
    console.log(this.team)
  }

  showDetail (person) {
    this.current = person
    this.$refs.personal.show()
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url("../assets/images/team/banner.jpeg");
}

.leader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-top: 90px;
  @media (max-width: 479px) {
    margin-top: 30px;
  }

  .avatar-container {
    margin-right: 40px;
    margin-bottom: 0;

    .avatar {
      width: 275px;
      height: 275px;
    }
  }

  .name {
    font-size: 36px;
    text-align: left;
  }

  .position {
    margin-top: 10px;
    font-size: 18px;
    text-align: left;
  }

  .desc {
    margin-top: 20px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #7f7f7f;
    white-space: pre-line;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;
    justify-content: space-between;

    .avatar-container {
      margin-left: 40px;
      margin-right: 0;
    }
  }
}

.members {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: center;
  margin-top: 90px;

  @media (max-width: 479px) {
    margin-top: 50px;
  }

  .member {
    width: 20%;
    overflow: hidden;

    &.adviser {
      width: 25%;
      @media (max-width: 479px) {
        width: 50%;
      }
    }

    @media (max-width: 479px) {
      width: 50%;
    }

    .all {
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.5s;
      cursor: pointer;

      .avatar {
        width: 100%;
      }

      &:hover {
        transform: translateX(calc(-100% - 1px));
      }

      .member_intro {
        transform: translateX(100%);
        padding: 1rem;
        font-size: 14px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          font-size: 1rem;
          font-weight: bold;
        }

        .position {
          margin-top: 3px;
          font-size: 0.875rem;
        }

        .desc {
          margin-top: 3px;
          white-space: pre-line;
          font-size: 12px;
          color: #7f7f7f;

          @media (max-width: 479px) {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .leader {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center !important;

    .avatar-container {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 20px;
    }

    .name {
      font-size: 1.5rem;
      text-align: center;
    }

    .position {
      font-size: 1rem;
      text-align: center;
    }

    .desc {
      margin-top: 10px;
    }

    &:nth-child(2n) {
      .avatar-container {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.person-card {
  margin-top: 1.5rem;
  cursor: pointer;

  &:hover {
    .content {
      img.avatar {
        transform: scale(1.1);
      }
    }
  }

  .content {
    border-radius: 1rem;
    overflow: hidden;

    .top {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
    }

    img.avatar {
      width: 100%;
      transition: all 0.5s;
    }

    .info {
      background: rgba(#e1e1e1, 0.3);
      padding: 0.5rem;

      .name {
        font-size: 1.2rem;
        font-weight: bold;
      }

      .position {
        margin-top: 0.5rem;
        height: 3.5rem;
        font-size: 0.9rem;
      }

      .contact {
        height: 1.5rem;
      }

      .icon {
        margin-right: 0.4rem;

        img {
          width: 24px;
        }
      }

      .link {
        margin-top: 0.5rem;
        font-size: 0.8rem;
        line-height: 1.5rem;

        img {
          margin-top: -3px;
          height: 1rem;
          width: auto;
        }
      }
    }
  }
}

.current {
  display: flex;
  align-items: center;
  background: #ffffff;
  height: 450px;

  @media (max-width: 479px) {
    height: 700px;
    overflow-y: auto;
    flex-direction: column;
    .left {
      width: 100% !important;

      .avatar {
        max-width: 100% !important;
        height: unset !important;
      }
    }
    .right {
      flex: none;
    }
  }

  .left {
    width: 450px;

    .avatar {
      object-fit: cover;
      object-position: top;
      width: 454px;
      height: 454px;
    }
  }

  .right {
    padding: 1.5rem;
    flex: 1;

    .name {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .position {
      margin-top: 0.5rem;
      font-size: 1.1rem;
    }

    .desc {
      margin-top: 0.5rem;
      white-space: pre-line;
      font-size: 14px;
      color: #7f7f7f;
    }
    .contact {
      margin-top: 0.5rem;
      .icon {
        margin-right: 0.4rem;

        img {
          width: 24px;
        }
      }
    }
  }
}

::v-deep .modal-body {
  padding: 0;
  border-radius: 1rem;
  overflow: hidden;
}

::v-deep .modal-content {
  background: transparent;
  border: none;
}
</style>
